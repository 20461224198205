.productWizard {
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
}

.productWizard #customCloseBtn {
  position: absolute !important;
}

.productWizard.iOS:not(.isDesktop):not(.landscape) {
  height: var(--product-wizard-height);
}

.productWizard.isDesktop.hasImg.regularProduct .wizardContentContainer {
  width: calc(100% - 500px) !important;
}

.productWizard.isDesktop.hasImg.mealDeal .wizardContentContainer {
  width: calc(100% - 520px) !important;
}

.productWizard.isDesktop.hasImg.mealDeal.largeImageDesign .wizardContentContainer {
  width: 50% !important;
}

.productWizard.isDesktop.hasImg.regularProduct.largeImageDesign .wizardContentContainer {
  width: 50% !important;
}

.productWizard .wizardContentContainer::-webkit-scrollbar,
.productWizard::-webkit-scrollbar {
  width: 10px;
  background: #d4d6d5;
}

.productWizard .wizardContentContainer::-webkit-scrollbar-thumb,
.productWizard::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* make scrollbar transparent */
  border-radius: 5px;
  border-top: 3px solid #d4d6d5;
  border-bottom: 3px solid #d4d6d5;
  border-left: 3px solid #d4d6d5;
  border-right: 3px solid #d4d6d5;
}

.productWizard .wizardHeader {
  padding: 20px 35px 15px 35px;
}

.productWizard .wizardStep ul.nav-tabs li::-webkit-scrollbar {
  display: none;
  overflow-x: -moz-scrollbars-none;
}

.productWizard .wizardStep {
  /* Experimental technology that only works on Firefox 64+ */
  scrollbar-color: var(--primary-color) #fff;
  scrollbar-width: thin;
  margin-bottom: 111px;
}

.productWizard .wizardStep .head {
  min-height: 44px;
  min-height: max-content;
}

.mealDeal {
  height: 520px;
}

.regularProduct .head h5 {
  font-weight: 400 !important;
}

.simpleProduct.noImg .wizardHeader {
  padding: 15px 35px 0px;
}

.productWizard.noImg .productWizardNavItem {
  justify-content: flex-start;
}

.productWizard.simpleProduct {
  flex-direction: column;
  height: auto;
  overflow-y: hidden;
  min-width: 500px;
}

.productWizard.simpleProduct.hasImg {
  max-height: inherit;
}
.productWizard.simpleProduct.hasImg .wizardHeader {
  overflow: auto;
}

.productWizard.simpleProduct.hasImg .wizardHeader::-webkit-scrollbar {
  width: 10px;
  background: #d4d6d5; /* make scrollbar transparent */
}

.productWizard.simpleProduct.hasImg .wizardHeader::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* make scrollbar transparent */
  border-radius: 5px;
  border-top: 3px solid #d4d6d5;
  border-bottom: 3px solid #d4d6d5;
  border-left: 3px solid #d4d6d5;
  border-right: 3px solid #d4d6d5;
}

.productWizard.hasImg.isMobile {
  min-width: auto;
}

.simpleProduct.noImg {
  min-width: 500px;
  max-width: 500px;
  height: auto;
}

.simpleProduct .wizardImageContainer {
  width: 100%;
  background-color: var(--menu-items);
}

.simpleProduct .wizardContentContainer:not(.productWizard.isTablet),
.mealDeal .wizardContentContainer:not(.productWizard.isTablet),
.regularProduct .wizardContentContainer:not(.productWizard.isTablet) {
  width: 100% !important;
  flex: 1;
}

.mealDeal.iOS .wizardStep {
  flex: 1 1 20%;
}

.productWizard.iOS .wizardStep {
  flex: 1;
}

.wizardStep.isApp ul,
.wizardStep.isApp .tab-content {
  overflow-x: hidden;
}

.wizardStep .head h5 {
  padding-top: 10px;
  padding-bottom: 12px;
  margin-left: 30px;
  margin-bottom: 0;
  font-size: 23px;
}

.wizardStep .sub h5,
.wizardStep .head.regProduct h5 {
  font-weight: 400 !important;
  padding-top: 10px;
  padding-bottom: 12px;
  margin-left: 30px;
  margin-bottom: 0;
  font-size: 23px;
}

.wizardStep .sub span > div,
.wizardStep .head.mealDealHeader span > div,
.wizardStep .head.regProduct span > div {
  padding: 5px 10px;
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}

.wizardStep .sub span > div span,
.wizardStep .head.mealDealHeader span > div span,
.regProduct span > div span {
  display: flex;
  align-items: center;
  color: #c8c8c8;
  font-size: 13px;
  background-color: #fff;
}

.wizardStep li .custom-radio-btn .checkmark::after {
  background-color: var(--primary-color);
}

.wizardStep li .custom-radio-btn input:checked ~ .checkmark {
  background-color: #fff;
  border-color: var(--primary-color);
}

.productWizardNavItem.nav-item {
  width: auto;
  overflow-x: auto;
}

.wizardStep ul li {
  width: calc(49% - 20px);
  display: inline-flex;
  justify-content: space-between;
  list-style-type: "none";
  margin-right: 1%;
  padding: 5px 10px;
}

.singleAnswers {
    display: block;
    width: calc(100% - 30px);
    padding: 15px 0px;
    margin: 0px 22px;
}

.singleAnswersListLabel {
    cursor: pointer !important;
    font-size: 16px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.wizardStep ul.nav.nav-tabs li {
  padding: 0 18px;
}

.regProduct.head {
  min-height: 44px;
}

.chefsNotes:focus,
.wizardButton:focus {
  outline: none;
}

.chefsNotes::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholder-color);
  opacity: 1; /* Firefox */
}

.chefsNotes:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder-color);
}

.chefsNotes::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholder-color);
}

.wizardImageContainer {
  width: 45%;
  background-color: var(--menu-items);
}

.wizardFooter {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.productWizard.isDesktop.hasImg.regularProduct .wizardFooter {
  max-width: calc(100% - 500px);
}

.productWizard.isDesktop.mealDeal.hasImg .wizardFooter {
  max-width: calc(100% - 520px);
}

/* Large Image Design */
.productWizard.isDesktop.mealDeal.hasImg.largeImageDesign .wizardFooter {
  max-width: 50%;
}

.productWizard.isDesktop.regularProduct.hasImg.largeImageDesign .wizardFooter {
  max-width: 50%;
}

.productWizard.isDesktop.mealDeal.hasImg.largeImageDesign {
  width: 1600px !important;
  max-width: 100%;
  height: calc(98vw / 3);
}

.productWizard.isDesktop.regularProduct.hasImg.largeImageDesign {
  width: 1600px !important;
  max-width: 100%;
  height: calc(98vw / 3);
}

.wizardFooter .wizardBackBtn {
  padding-left: 10px;
  width: 5%;
}

@media (min-width: 1600px) {
  .productWizard.isDesktop.mealDeal.hasImg.largeImageDesign {
    width: 1600px !important;
    max-width: 100%;
    height: calc(1600px / 3);
  }
  
  .productWizard.isDesktop.regularProduct.hasImg.largeImageDesign {
    width: 1600px !important;
    max-width: 100%;
    height: calc(1600px / 3);
  }
}

.wizardButton > div:first-child {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
  width: 50%;
  font-weight: 300;
}

.wizardButton > div:last-child {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  width: 50%;
  font-weight: 300;
}

.wizardFooter > div {
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 6px;
  align-items: center;
}

.landscape .wizardImageContainer {
  height: auto !important;
}

.productWizard.isTablet.hasImg.landscape .wizardContentContainer {
  overflow-y: auto;
}

.productWizard.isTablet.hasImg.landscape .wizardStep > ul:not(.nav) {
  padding-bottom: 111px !important; /** padding because the height is auto and the margin needs hard values */
}

.productWizard.isTablet.hasImg.landscape .tab-content .tab-pane.active ul {
  margin-bottom: 111px !important;
}

.productWizard.isDesktop.noImg .wizardStep .tab-content {
  margin-bottom: 0 !important;
}

.productWizard.isTablet.hasImg.landscape .wizardFooter {
  height: calc(100% - 500px);
}
/* APP STYLES */
#contentContainerTransparent .productWizard.hasImg {
  height: 100%;
}

#contentContainerTransparent .productWizard .wizardStep {
  margin-bottom: 0px;
}

#contentContainerTransparent .productWizard::-webkit-scrollbar,
#contentContainerTransparent .productWizard .wizardStep::-webkit-scrollbar {
    /*display: none;*/
}

#contentContainerTransparent .productWizard {
  -ms-overflow-style: none;
  min-width: 100%;
  max-width: 100%;
}

#contentContainerTransparent .productWizard.iOS.hasImg {
  overflow-y: auto !important;
  height: var(--product-wizard-height) !important;
}

#contentContainerTransparent .productWizard.iOS.hasImg .wizardContentContainer {
  min-height: max-content !important;
}

#contentContainerTransparent .productWizard.iOS.noImg .wizardStep {
  height: auto !important;
}

#contentContainerTransparent
  .productWizard.iOS.noImg
  .wizardStep
  .ul:not(.nav) {
  display: flex;
  flex-direction: column;
}

#contentContainerTransparent .productWizard .wizardFooter {
  z-index: 10;
}
/*  */
#contentContainerTransparent .productWizard .wizardStep ul:not(.nav) {
  margin-bottom: 111px !important;
  padding-bottom: 0px !important;
}

#contentContainerTransparent .productWizard .wizardStep .tab-content {
  margin-bottom: 0px !important;
}

#contentContainerTransparent
  .productWizard.hasImg.isTablet
  .wizardImageContainer {
  margin: auto;
}

#contentContainerTransparent .productWizard.iOS.noImg .wizardContentContainer {
  overflow: auto;
}
/* END OF APP STYLES */

/* Small devices */
@media (max-width: 768px) {
    .modalDialog.products {
        display: flex;
        max-width: 500px;
        max-height: 100%;
    }

    .productWizard {
        flex-direction: column;
        height: auto;
        overflow-y: auto;
    }

    .simpleProduct.noImg {
        max-width: 100%;
    }

    .simpleProduct {
        min-width: 60vw;
    }

    .simpleProduct.noImg .wizardStep {
        padding-bottom: 111px;
    }
    
    .productWizard.noImg:not(.simpleProduct) {
        min-width: 500px;
        min-height: 60vh;
        max-height: 75vh;
    }

    .regularProduct, .mealDeal {
        min-width: 500px;
        max-width: 500px;
        max-height: 85vh;
    }

    .productWizard.simpleProduct.hasImg {
        max-height: 100vh;
    }

    /* .mealDeal, */
    .mealDeal.iOS {
        min-width: 70vw;
        max-height: 92vh;
    }

    .productWizard.hasImg.isDesktop .wizardContentContainer {
        width: 100% !important;
    }

    .productWizard.hasImg.isDesktop.iOS .tab-content > ul:not(.landscape) {
        margin-bottom: 111px;
    }

    .wizardContentContainer {
        width: 100% !important;
        max-width: 100vw;
    }

    .wizardImageContainer {
        width: 100%;
        background-color: var(--menu-items);
        max-height: 500px;
    }

    .productWizard.iOS .wizardStep,
    .productWizard.regularProduct .wizardStep {
        flex: 1
    }

  .wizardStep ul li {
    width: calc(98% - 20px);
    /*padding-bottom: 0;*/
    padding: 5px 10px;
  }

    .wizardStep .sub h5, .head h5 {
        margin-left: 20px;
    }

    .wizardStep .sub span > div {
        padding: 5px;
        margin-right: 10px;
    }

    .productWizard #customCloseBtn {
        margin-bottom: -39px;
        position: sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
    }

    .productWizard.simpleProduct.noImg #customCloseBtn {
        position: absolute !important;
    }

    .productWizard.isDesktop.hasImg .wizardFooter {
        max-width: 100% !important;
    }

    .wizardFooter {
        position: sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        bottom: 0;
        background-color: #fff;
    }

    .wizardFooter .wizardBackBtn {
        padding-left: 0;
        width: auto;
    }

    .wizardButton {
        margin-right: 5px;
        font-size: 15px;
    }

    .wizardButton > div:first-child {
        justify-content: flex-start;
        padding-left: 15px;
    }

    .wizardButton > div:last-child {
        padding-right: 0px;
    }

    .wizardFooter > div {
        padding: 14px 6px;
    }

    .productWizard.hasImg,
    .productWizard.noImg {
        overflow-y: auto !important;
    }

    .productWizard.hasImg .wizardStep {
        overflow: initial !important;
    }

    .productWizard.noImg .wizardStep,
    .productWizard.hasImg.mealDeal .wizardStep {
        overflow: initial !important;
    }

    .productWizard.hasImg .wizardContentContainer,
    .productWizard.noImg .wizardContentContainer  {
        overflow-y: initial !important;
    }

    .productWizard.hasImg .wizardFooter,
    .productWizard.noImg .wizardFooter {
        width: 100%;
        position: fixed;
        z-index: 10;
    }

    .productWizard.simpleProduct.hasImg .wizardStep {
        min-height: 111px !important;
    }
    /* APP STYLES */
    #contentContainerTransparent .productWizard.iOS.noImg.simpleProduct.isMobile {
        overflow-y: auto !important;
    }

    #contentContainerTransparent .productWizard:not(.iOS),
    #contentContainerTransparent .productWizard.hasImg:not(.iOS) {
        overflow-y: visible !important;
        height: auto;
    }

    #contentContainerTransparent .productWizard::-webkit-scrollbar,
    #contentContainerTransparent .productWizard .wizardStep::-webkit-scrollbar {
        /*display: none;*/
    }

    #contentContainerTransparent .productWizard {
        -ms-overflow-style: none;
    }

    #contentContainerTransparent .productWizard.hasImg .wizardStep {
        min-height: max-content !important;
    }

    #contentContainerTransparent .productWizard:not(.iOS) .wizardStep > ul {
        padding-bottom: 0px !important;
    }

    #contentContainerTransparent .productWizard.simpleProduct.hasImg .wizardStep {
        min-height: 111px !important;
    }

    .productWizard.pWA.iOS .wizardFooter {
      max-height: 118px !important;
    }
    /* END OF APP STYLES */
}

/* Extra small devices like smart phones */
@media (max-width: 650px) {
    .modalDialog.products {
        display: flex;
        width: 100vw;
    }

    .productWizard.iOS.simpleProduct {
        width: 100vw;
        height: var(--product-wizard-height) !important;
    }
    
    .productWizard.simpleProduct.noImg:not(.iOS):not(.isTablet),
    .productWizard.noImg:not(.iOS):not(.isTablet),
    .productWizard.simpleProduct:not(.iOS):not(.isTablet),
    .pruductWizard.noImg.mealDeal:not(.iOS):not(.isTablet),
    .productWizard.noImg.regularProduct:not(.iOS):not(.isTablet) {
        height: 100vh;
        /* height: -webkit-fill-available; */
    }

    .productWizard.productWizard.simpleProduct.hasImg .wizardHeader {
        overflow: initial;
    }

    .productWizard.noImg .wizardContentContainer {
        flex: 1;
    }

    .productWizard.simpleProduct.noImg .wizardStep,
    .productWizard.simpleProduct .wizardStep,
    .productWizard.noImg .wizardStep {
        flex: 1
    }

    .simpleProduct.noImg:not(.isDesktop):not(.isTablet) {
        min-width: 100vw;
    }

    .productWizard.noImg {
        max-height: 100vh;
    }

    .productWizard.iOS.regularProduct,
    .productWizard.iOS.noImg,
    .productWizard.iOS.mealDeal {
        max-width: 100%;
        max-height: 100%;
    }

    .productWizard.regularProduct,
    .productWizard.noImg,
    .productWizard.mealDeal {
        max-width: 100%;
        max-height: 100vh;
    }
    /* style selector below is needed to counter the :not selector which takes higher prececende than order */
    .products .productWizard.noImg:not(.iOS):not(.isTablet) {
        min-height: 100vh;
        /* min-width: 100vw; */
    }

    .modalDialog.products .productWizard.notIntegrated:not(.isDesktop):not(.isTablet) {
        max-height: 100vh;
    }
    
    .modalDialog.products .productWizard.notIntegrated.isMobile:not(.iOS) {
        max-height: 100vh;
    }

    /* APP STYLES */
    #contentContainerTransparent .productWizard::-webkit-scrollbar,
    #contentContainerTransparent .productWizard .wizardStep::-webkit-scrollbar {
        /*display: none;*/
    }
    /* END OF APP STYLES */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 1119px) {
  .modalDialog.products {
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
  }

  .productWizard {
    flex-direction: row;
  }

  .mealDeal,
  .regularProduct {
    width: 100vw;
  }

  .productWizard.noImg {
    width: 67vw;
  }

  .productWizard.simpleProduct.hasImg {
    max-height: 100vh;
    max-width: 500px;
  }

  .productWizard #customCloseBtn {
    position: absolute !important;
  }

  .wizardContentContainer {
    width: 100% !important;
  }

  .wizardStep {
    flex: 2 1 0 !important;
  }

  .productWizard #customCloseBtn {
    margin-bottom: -39px;
  }

  .sub h5 {
    padding-left: 20px;
  }

  .productWizard.simpleProduct.hasImg {
    overflow-y: auto !important;
  }

  .productWizard.simpleProduct.hasImg .wizardContentContainer {
    overflow-y: visible !important;
  }

  .productWizard.simpleProduct.hasImg .wizardHeader {
    overflow: visible;
  }

  .productWizard.simpleProduct.hasImg .wizardFooter {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  .productWizard.simpleProduct.hasImg .wizardHeader {
    padding-bottom: 111px;
  }
}

/* Extra large devices (large desktops, 1120px and up) */
@media (min-width: 1120px) {
  .modalDialog.products {
    width: auto;
    height: auto;
    max-width: 98%;
    /* max-height: 560px; */
    max-height: 100vh;
    background-color: transparent;
  }

  .modalDialog.products .productWizard {
    width: 1120px;
    /* min-height: 510px; */
  }

  .modalDialog.products .productWizard.simpleProduct {
    /* width: auto; */
    max-width: 500px;
    max-height: 92vh;
  }

  .modalDialog.products .productWizard.simpleProduct.hasImg.largeImageDesign {
    /* width: auto; */
    max-width: 600px;
    max-height: 92vh;
  }

  .productWizard.simpleProduct .wizardContentContainer {
    overflow-y: hidden !important;
  }

  .productWizard.simpleProduct.noImg {
    height: auto;
  }

  .productWizard .wizardStep {
    flex: 2 1;
  }

  .modalDialog.products .productWizard.noImg {
    width: 800px;
  }

  .productWizard.simpleProduct.hasImg {
    overflow-y: auto !important;
  }

  .productWizard.simpleProduct.hasImg .wizardContentContainer {
    overflow-y: visible !important;
    width: 100% !important;
  }

  .productWizard.simpleProduct.hasImg .wizardHeader {
    overflow: visible;
  }

  .productWizard.simpleProduct.hasImg .wizardFooter {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  /* .productWizard.simpleProduct.hasImg .wizardHeader {
        padding-bottom: 111px;
    } */
}

/* .productWizard.productWizard.hasImg::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 8px;
    border-top: 3px solid #ffc107;
    border-bottom: 3px solid #ffc107;
    border-left: 3px solid #ffc107;
    border-right: 3px solid #ffc107;
    z-index: 100000000000;
}

.productWizard.hasImg::-webkit-scrollbar {
    width: 13px;
    background: blue;
} */

/* RTL Arabic */

html[dir="rtl"] .wizardStep .head h5 {
  margin-right: 30px;
  margin-left: 0;
}

html[dir="rtl"] .wizardStep .sub h5 {
  margin-right: 30px;
  margin-left: 0;
}

html[dir="rtl"] .wizardStep .head.regProduct h5 {
  margin-right: 30px;
  margin-left: 0;
}

html[dir="rtl"] .wizardStep .sub span > div {
  margin-left: 20px;
  margin-right: 0px;
}

html[dir="rtl"] .wizardStep .head.mealDealHeader span > div {
  margin-left: 20px;
  margin-right: 0px;
}

html[dir="rtl"] .wizardStep .head.regProduct span > div {
  margin-left: 20px;
  margin-right: 0px;
}

html[dir="rtl"] .wizardStep ul li {
  margin-right: 0;
  margin-left: 1%;
}

html[dir="rtl"] .wizardFooter .wizardBackBtn {
  padding-right: 10px;
  padding-left: 0;
}

html[dir="rtl"] .wizardButton > div:first-child {
  padding-right: auto;
  padding-left: 0;
}

html[dir="rtl"] .wizardButton > div:last-child {
  padding-right: 0;
  padding-left: 10px;
}

/* RTL Arabic Max Width 768px */

@media (max-width: 768px) {
  html[dir="rtl"] .wizardStep .sub h5,
  .head h5 {
    margin-left: 0px;
    margin-right: 20px;
  }

  html[dir="rtl"] .wizardStep .sub span > div {
    margin-right: 0px;
    margin-left: 10px;
  }

  html[dir="rtl"] .wizardFooter .wizardBackBtn {
    padding-left: auto;
    padding-right: 0;
  }

  html[dir="rtl"] .wizardButton {
    margin-right: 0px;
    margin-left: 5px;
  }

  html[dir="rtl"] .wizardButton > div:first-child {
    padding-left: 0px;
    padding-right: 15px;
  }

  html[dir="rtl"] .wizardButton > div:last-child {
    padding-right: auto;
    padding-left: 0px;
  }
}

/* RTL Arabic Large devices (desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 1119px) {
  html[dir="rtl"] .sub h5 {
    padding-left: 0px;
    padding-right: 20px;
  }
}

/* Large Image Design RTL */

html[dir="rtl"] .productWizard.isDesktop.hasImg.largeImageDesign #customCloseBtn {
  right: -10px !important;
}

html[dir="rtl"] .productWizard.isDesktop.noImg.largeImageDesign #customCloseBtn {
  right: -10px !important;
}
